section#partnerLogosSection,
section#fundingLogosSection{
  padding: 1rem 0 6rem 0;
  .title {
    font-size: $font-size-22;
    margin: 1.5rem 0;
  }
    .row.center {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    }

  .partnerLogo {
    display: block;
    margin: 1.875rem auto;
    width: auto!important;
    max-height: 100px;
  }
}