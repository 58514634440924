section#factsSection {
  position: relative;
  min-height: 4rem;
  background: $color-lighter-blue;

  .sectionImage {
    position: relative;
    display: block;
    margin: 0 auto;
    padding: 2rem 0;
    @include media-breakpoint-up(md){
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .sectionContent {
    height: 100%;
    padding: 2rem 0;
    ul.factList {
      position: relative;
      padding: 0;
      margin: 2.5rem 0;
      text-align: center;
      @include media-breakpoint-up(md){
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
      }
      li {
        list-style: none;
        font-size: $font-size-27;
        line-height: 1.2;
        @include media-breakpoint-up(lg){
          font-size: $font-size-41;
        }
      }
    }
  }
}