#siteWrapper {
  //max-width: $site-wrapper-width;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
}


.wide_content {
    max-width: 100%;
    
    @include media-breakpoint-up(lg) {
    max-width: $site-wrapper-width;
    }
}

.col.menu {
    background-color:#abdcec;
    
    @include media-breakpoint-up(lg) {
    min-width: $min-menu-left-width!important;
    }
}