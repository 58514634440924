section#stage {
  position: relative;
  background-position: center center;
  background-image: url(../../img/video_background.jpg);
  background-size:cover;
    


  .title {
    position: absolute;
    left: 50%;
    bottom: 2.5rem;
    transform: translateX(-50%);
    color: $color-white;
    text-align: center;
    font-size: $font-size-22;
    width: 90%;

    @include media-breakpoint-up(md){
      font-size: $font-size-41;
      bottom: 3rem;
    }

    @include media-breakpoint-up(lg){
      font-size: $font-size-70;
      bottom: 6rem;
    }
  }
}


#center_wrapper {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;


  @include media-breakpoint-up(sm) {
    padding-top: 0.938rem;
    padding-bottom: 0.938rem;
    padding-left: 15px;
    padding-right: 15px;
  }

  &:hover {
    #video {
      display: block;
    }
  }

  #video {
    position: absolute;
    z-index: 100;
    display: none;
    margin-left: 4px;
    margin-top: 4px;
    height: 30px;
    width: 30px;
    background-image: url(../../img/close.svg);
    cursor: pointer;
  }

  .video {
    max-width: 75rem;
    display: block;
    height: auto;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.video_wrapper {
  max-height: 900px;
}

.video_wrapper.hide {
  background-color: red;
  transition: 1s;
  opacity: 0;
  max-height: 0px;
  overflow-y: hidden;
}

section hr.divider{
    position: relative;
    border-top-color: $color-black;
    margin-top: -30px;
    margin-bottom: -10px;
}

section.columnContentSection .sectionImage.img-fluid {
    min-width: 100%;
}

/*.content img {
    width: 100%;
    height: auto;
    @include media-breakpoint-up(md){
          width: unset;
          height: unset;

 }
}*/

#content p {
    width: 100%;
    overflow: auto;

    @include media-breakpoint-up(md){
        width: unset;

 }
}