@mixin make-font($size: null, $weight: null, $family: $font-family-base, $line-height: null, $color: null) {
    @if $color {
        color: $color
    }
    @if $size {
        @include flow-font-size($size)
    }
    @if $weight {
        font-weight: $weight
    }
    @if $family {
        font-family: $family
    }
    @if $line-height {
        line-height: $line-height
    }
    margin: 0;
}

/**
 * @license
 * MyFonts Webfont Build ID 3769969, 2019-06-04T12:09:26-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: FF Clan Offc Web Pro Book SC by FontFont
 * URL: https://www.myfonts.com/fonts/fontfont/clan-pro/offc-pro-book-sc/
 * Copyright: 2010 published by FSI FontShop International GmbH
 *
 * Webfont: FF Clan Offc Web Pro Medium SC by FontFont
 * URL: https://www.myfonts.com/fonts/fontfont/clan-pro/offc-pro-medium-sc/
 * Copyright: 2010 published by FSI FontShop International GmbH
 *
 * Webfont: FF Clan Web Pro Book by FontFont
 * URL: https://www.myfonts.com/fonts/fontfont/clan-pro/pro-book/
 * Copyright: 2010 Lukasz Dziedzic published by FSI FontShop International GmbH
 *
 * Webfont: FF Clan Web Pro Medium by FontFont
 * URL: https://www.myfonts.com/fonts/fontfont/clan-pro/pro-medium/
 * Copyright: 2010 Lukasz Dziedzic published by FSI FontShop International GmbH
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3769969
 * Licensed pageviews: 50,000
 *
 * © 2019 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/398671");


@font-face {font-family: 'FFClanOffcWebProBookSC';src: url('../../fonts/ff-clan-offc/398671_0_0.eot');src: url('../../fonts/ff-clan-offc/398671_0_0.eot?#iefix') format('embedded-opentype'),url('../../fonts/ff-clan-offc/398671_0_0.woff2') format('woff2'),url('../../fonts/ff-clan-offc/398671_0_0.woff') format('woff'),url('../../fonts/ff-clan-offc/398671_0_0.ttf') format('truetype');}


@font-face {font-family: 'FFClanOffcWebProMediumSC';src: url('../../fonts/ff-clan-offc/398671_1_0.eot');src: url('../../fonts/ff-clan-offc/398671_1_0.eot?#iefix') format('embedded-opentype'),url('../../fonts/ff-clan-offc/398671_1_0.woff2') format('woff2'),url('../../fonts/ff-clan-offc/398671_1_0.woff') format('woff'),url('../../fonts/ff-clan-offc/398671_1_0.ttf') format('truetype');}


@font-face {font-family: 'FFClanWebProBook';src: url('../../fonts/ff-clan-offc/398671_2_0.eot');src: url('../../fonts/ff-clan-offc/398671_2_0.eot?#iefix') format('embedded-opentype'),url('../../fonts/ff-clan-offc/398671_2_0.woff2') format('woff2'),url('../../fonts/ff-clan-offc/398671_2_0.woff') format('woff'),url('../../fonts/ff-clan-offc/398671_2_0.ttf') format('truetype');}


@font-face {font-family: 'FFClanWebProMedium';src: url('../../fonts/ff-clan-offc/398671_3_0.eot');src: url('../../fonts/ff-clan-offc/398671_3_0.eot?#iefix') format('embedded-opentype'),url('../../fonts/ff-clan-offc/398671_3_0.woff2') format('woff2'),url('../../fonts/ff-clan-offc/398671_3_0.woff') format('woff'),url('../../fonts/ff-clan-offc/398671_3_0.ttf') format('truetype');}


.FFClanOffcWebProBookSC {
    font-family: FFClanOffcWebProBookSC;
    font-weight: normal;
    font-style: normal;
}
.FFClanOffcWebProMediumSC {
    font-family: FFClanOffcWebProMediumSC;
    font-weight: normal;
    font-style: normal;
}
.FFClanWebProBook {
    font-family: FFClanWebProBook;
    font-weight: normal;
    font-style: normal;
}
.FFClanWebProMedium {
    font-family: FFClanWebProMedium;
    font-weight: normal;
    font-style: normal;
}