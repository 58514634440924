
header {
  padding: 1rem 0.938px;
  background: $color-lighter-green;

  @include media-breakpoint-up(lg) {
    padding: 1.5rem 1.8rem;
    //max-width: $site-wrapper-width;
    //margin: 0 auto;
  }

  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: $site-wrapper-width !important;
  }

  .headerRow {
    .movebankLogoWrapper {
      position: relative;
      width: 100%;
      margin-left: -8vw;
      @include media-breakpoint-up(sm) {
        margin-left: 0vw;
      }
      @include media-breakpoint-up(md) {
        padding-right: 0;
        max-width: 15rem;
        margin-left: unset;
        padding-bottom: 1.5rem;
      }

      .logo {
        width: 100%;
        display: block;
        max-width: 16rem;
        margin: 0 auto 0 auto;
        @include media-breakpoint-up(md) {
          max-width: 16rem;
        }
      }

      .claim {
        position: static;
        display: block;
        white-space: nowrap;
        margin: 5px auto 0px auto;
        text-align: center;
        bottom: 10px;
        @extend .FFClanWebProMedium;
        color: $color-dark-blue;
        font-size: $font-size-16;
        letter-spacing: 1px;
        @include media-breakpoint-up(md) {
          font-size: $font-size-14;
          position: absolute;
          left: 41%;
          right: unset;
        }
        //@include media-breakpoint-up(lg) {
        //  font-size: 23px;
        //  position: absolute;
        //  right: -155px;
        //  left: unset;
        //  bottom: 0px;
        //}
      }
    }

    .col-md-8 {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-content: flex-end;
      align-items: center;
      @include media-breakpoint-up(md) {
        align-items: flex-end;
      }
    }

    nav.mainNavigation {
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      box-shadow: 0 0 10px 0 rgba(212, 212, 212, 0.3);
      width: 18.75rem;
      //      max-width: 95%;
      overflow-x: auto;
      z-index: 220;
      transition: transform 0.4s ease-out;
      transform: translate(-120%, 0);
      background: $color-white;
      text-align: left;
      padding: 1.5rem 2rem;

      @include media-breakpoint-up(md) {
        position: relative;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        background: none;
        overflow-x: visible;
        width: auto;
        padding: 0;
        //   text-align: right;
        margin-top: 0rem;
        transform: none;
        box-shadow: none;
      }

      ul {
        padding: 0;
        //margin: 0;
        li {
          position: relative;
          list-style: none;
          display: block;

          &:first-child {
            margin-left: 0;
          }

          margin-bottom: 1rem;
          @include media-breakpoint-up(md) {
            display: inline-block;
            margin: 0 0 0 1rem;

          }

          a {
            display: block;
            font-size: $font-size-16;
            text-decoration: none;
            line-height: 2rem;
            padding: 0rem 0.625rem;
            border-radius: 0.5rem;
            -moz-osx-font-smoothing: auto !important;
            //@include media-breakpoint-up(xl) {
            //  font-size: $font-size-22;
            //  padding: 0rem 1rem;
            //  line-height: 2.7rem;
            //  height: 2.5rem;
            //}
            /*     &:hover {
                     background-color: $color-dark-blue;
                     color: $color-white;
                 }*/
          }

          &:hover {
            ul {
              display: block;
            }
          }

          a.active {
            background-color: $color-dark-blue;
            color: $color-white;
          }

          ul {
            padding-top: 0.5rem;
            padding-left: 1rem;
            @include media-breakpoint-up(md) {
              display: none;
              position: absolute;
              left: -0.75rem;
              background: $color-white;
              text-align: left;
              padding: 0.25rem 0.75rem;
            }

            li {
              display: block;
              margin: 0.5rem 0;
              @include media-breakpoint-up(md) {
                margin: 0;
              }

              a {
                display: block;
                font-size: $font-size-14;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }

    nav.subNavigation {
      position: relative;
      text-align: center;
      //   margin-top: 1rem;
      @include media-breakpoint-up(md) {
        //        text-align: right;
      }

      ul {
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          display: inline-block;
          margin: 1rem 0.15rem 0px 0.15rem;
          background-color: $color-white;
          border-radius: 0.5rem;

          &.searchInputWrapper {
            display: block;
            // margin-bottom: 1rem;
            margin-right: 0;
            @include media-breakpoint-up(sm) {
              display: inline-block;
              margin-bottom: 0;
            }
          }

          &:first-child {
            margin-left: 0;
            margin-right: -.5rem;
            border-radius: 0rem;
            background-color: transparent;
          }

          &#movebank-logout-li {
            background-color: transparent;
            margin-left: -.5rem;

          }

          &:last-child {
            margin-right: 0;
          }

          &.button.login {
            background-color: $color-green;
          }

          &.button.login a {
            color: $color-white !important;
          }

          &#movebank-logout-li a {
            color: $color-green;
          }

          &#movebank-account-li {
            position: relative;
            display: inline-block;
            background-color: $color-green;
            border-radius: 3.125rem;
            margin: 0 0.313rem 0 0;
            width: 2rem;
            height: 2rem;
            //@include media-breakpoint-up(xl) {
            //  margin: 0.313rem 0.625rem 0 0;
            //  width: 2.5rem;
            //  height: 2.5rem;
            //}
          }

          &:hover {
            ul {
              display: block;
            }
          }

          #movebank-about{
            color: $color-green;
          }

          a.active {
            background-color: $color-dark-blue;
            color: $color-white;
          }

          ul {
            padding-top: 0.5rem;
            padding-left: 1rem;
            @include media-breakpoint-up(sd) {
              display: none;
              position: absolute;
              z-index: 999;
              right: -3rem;
              background: $color-white;
              opacity: 0.95;
              text-align: left;
              padding: 0.25rem 0.75rem;
            }

            li {
              display: block;
              margin: 0.5rem 0;

              #dropdown-profile-name{
                color: $color-black!important;
                font-weight: bold;
                letter-spacing: 0.2em;
              }

              @include media-breakpoint-up(md) {
                margin: 0;
              }

              a {
                display: block;
                color: $color-green;
                font-size: $font-size-14;
                white-space: nowrap;
              }
            }
          }

          a {
            color: $color-black;
            display: inline-block;
            line-height: 2rem;
            padding: 0rem .625rem;
            -moz-osx-font-smoothing: auto !important;
            text-decoration: none;
            //@include media-breakpoint-up(xl) {
            //  font-size: $font-size-18;
            //  line-height: 2.5rem;
            //  padding: 0rem 1rem;
            //}
          }

          a#movebank-account {
            display: inline-block;
            padding: 0px;
            padding-bottom: 10px;
            color: $color-white;
            font-size: 12px;
            margin-left: auto;
            margin-right: auto;
            line-height: 2rem;
            //@include media-breakpoint-up(xl) {
            //  line-height: 2.5rem;
            //  font-size: 16px;
            //}
          }
        }

        .search-icon {
          position: absolute;
          background-color: white;
          z-index: 2;
          right: 0px;
          width: 30px;
          height: 30px;
          border-radius: 0.5rem;
          color: transparent;
          background-image: url(../../img/search.svg);
          background-position: left center;
          background-repeat: no-repeat;
          background-size: 22px;
          display: block;

          @include media-breakpoint-up(sm) {
            background-size: 23px;
            background-position: left center;
            width: 30px;
            height: 30px;
          }

          //@include media-breakpoint-up(xl) {
          //  background-size: 28px;
          //  background-position: center center;
          //  width: 40px;
          //  height: 40px;
          //}
        }

        input.searchInput {
          background: none;
          box-shadow: none;
          border: none;
          text-align: left;
          padding: 0rem 40px 0rem .5rem;
          background-color: transparent;
          line-height: 2rem;
          transition: width 500ms, opacity 250ms;

          @include media-breakpoint-up(sm) {
            padding-right: 0px;
            width: 35px;
            opacity: 0;
          }

          @include media-breakpoint-up(md) {
            padding-right: 0px;
            width: 35px;
            opacity: 0;

          }
          //@include media-breakpoint-up(xl) {
          //  padding-right: 0px;
          //  width: 40px;
          //  line-height: 2.5rem;
          //  font-size: $font-size-18;
          //  opacity: 0;
          //}
        }

        .searchInputWrapper input.searchInput.open {
          background: none;
          box-shadow: none;
          border: none;
          background-color: transparent;
          line-height: 2rem;
          opacity: 1;

          @include media-breakpoint-up(sm) {
            width: 205px;
          }

          @include media-breakpoint-up(md) {
            width: 205px;

          }
          //@include media-breakpoint-up(xl) {
          //  width: 300px;
          //  line-height: 2.5rem;
          //  font-size: $font-size-18;
          //}
        }
      }
    }
  }
}
   