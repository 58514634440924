/* === Bootstrap Global === */
$enable-rounded: true;

$site-wrapper-width: 1650px;
$min-menu-left-width: 180px;
$max-menu-left-width: 350px;

/* === Header / Navigation === */
$header-height-desktop: 12rem;


/* === Colors === */
$color-white: #FFFFFF;
$color-black: #000000;
$color-lighter-green: #d9e9e6;
$color-light-green: #97c4be;
$color-green: #008476;
$color-lighter-blue: #d3edf4;
$color-light-blue: #abdcec;
$color-blue: #70c9e6;
$color-dark-blue: #00addc;
$color-link-blue: #0076A8;
$color-heading-lv1: #00ADEF;
$color-heading-lv2: #027B6E;
$color-heading-lv3: #056c9b;

$color-grey: #eee;
$color-darkgrey: #adadad;

$color-primary: $color-green;
$color-text: $color-black;

$font-size-14: 0.875rem;
$font-size-15: 0.938rem;
$font-size-16: 1rem;
$font-size-18: 1.125rem;
$font-size-22: 1.375rem;
$font-size-24: 1.5rem;
$font-size-27: 1.6875rem;
$font-size-30: 1.875rem;
$font-size-41: 2.5625rem;
$font-size-70: 4.375rem;
