section.columnContentSection {
  position: relative;
  min-height: 4rem;
  &#aboutUs {
    background: $color-light-blue;
  }
  &#blog {
    background: $color-lighter-green;
  }
  &#getStarted {
    background: $color-blue;
  }
  &#data {
    background: $color-light-green;
  }

  .sectionImage {
    position: relative;
    margin: 1rem 0;
    @include media-breakpoint-up(md){
      margin: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .sectionContent {
    h2 {
      @extend .FFClanWebProMedium;
      font-size: $font-size-27;
      margin-top: 1.5rem;
    }
    margin: 1rem 0;
  }
}