footer {
  background: $color-light-green;
  padding: 1rem 0;
  @include media-breakpoint-up(sm) {
    padding: 0 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 0 0;
    //max-width: $site-wrapper-width;
    //margin: 0 auto;
  }

  .container {
    background-color: transparent;
    padding: 1rem;

    @include media-breakpoint-up(sm) {
      max-width: 720px;
    }

    @include media-breakpoint-up(md) {
      max-width: 960px;
    }

    @include media-breakpoint-up(lg) {
      max-width: $site-wrapper-width;
    }
  }

  ul.footerLinks {
    padding: 0;
    margin: auto 0;
    text-align: left;

    li {
      list-style: none;

      a {
        font-size: $font-size-18;
        text-decoration: none;
        color: $color-black;
        @include media-breakpoint-up(sm) {
          font-size: $font-size-22;
        }
      }
    }
  }

  .logo {
    align-content: baseline;
    text-align: right;

    @include media-breakpoint-down(sm){
      text-align: center;
    }

    img {
      width: 100%;
      max-width: 450px;
    }
  }

  .socialMediaIcons {
    display: flex;

    ul {
      margin-left: auto;
      padding: 0 0rem;
      max-width: 150px;

      @include media-breakpoint-up(sm) {
        max-width: 100px;
      }
      @include media-breakpoint-up(md) {
        max-width: 100px;
        margin: 0 auto 0 0;
      }

      li {
        list-style: none;
        display: inline-block;
        padding: .5rem;

        a.socialMediaLink {
          img {
            width: 10vw;
            max-width: 40px;
            height: auto;
            @include media-breakpoint-up(sm) {
              width: 30px;
            }
          }
        }
      }
    }
  }

  .leftBorder {
    @include media-breakpoint-up(lg) {
      border-left: 1px solid $color-black;
      padding-left: 2rem;
    }
  }

  .infoText {
    font-size: $font-size-14;
    color: $color-black;
    text-align: left;
    margin: auto;

    @include media-breakpoint-down(sm){
      text-align: center;
    }

    p {
      margin: auto;
    }
  }
}

footer .container {
  display: flex !important;
}

.mobile {
  flex: 2 0 50%;
}

.flow-end {
  display: flex;
  align-content: end;
}