.container-fluid > .row.side {
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
  }
}


#detailPageContent {
  aside.sidebar {
    position: relative;
    height: 100%;
    margin: 0;
    padding: 1rem;
    background: $color-light-blue;

    @include media-breakpoint-up(lg) {
      padding-right: 10%;
    }

    .col {
      padding: 0;
      margin: 0;

      @include media-breakpoint-up(md) {
        position: absolute;
        position: -webkit-sticky;
        position: -moz-sticky;
        position: -ms-sticky;
        position: -o-sticky;
        position: sticky;
        top: 30px;
        bottom: auto;
      }

      nav.sidebarNavigation {

        @include media-breakpoint-up(lg) {
          width: 80%;
        }

        ul {
          padding: 0;
          margin: 0;

          li {
            list-style: none;
            margin-bottom: 0.75rem;

            &:last-of-type {
              margin-bottom: 0;
            }

            a {
              color: $color-black;
              text-decoration: none;

              &.active {
                font-weight: $font-weight-bold;
              }
            }
          }
        }
      }
    }
  }

  .contentWrapper {
    padding: 2rem 0rem;
    @include media-breakpoint-up(md) {
      padding: 2rem 0.938rem;
    }

    nav.breadcrumbs {
      margin-bottom: 1rem;

      ol {
        list-style: none;
        padding-left: 0;

        li {
          display: inline-block;
          margin-right: 1rem;

          a {
            color: $color-black;
          }
        }
      }
    }

    .language_select {

      ul {
        list-style: none;
        padding-left: 0rem;
      }

      li {
        display: inline-block;
        margin-right: 1.25rem;

      }

      .language img {
        height: 2.5rem;
        width: 2.5rem;
      }
    }

    .content {
      a {
        color: $color-link-blue;
      }

      .headline-lv1 {
        font-size: $font-size-27;
        font-weight: $font-weight-bold;
        color: $color-heading-lv1;
        margin-top: 2rem;
        margin-bottom: 1rem;
      }

      .headline-lv2 {
        font-size: $font-size-24;
        font-weight: $font-weight-bold;
        color: $color-heading-lv2;
        margin-top: 2rem;
        margin-bottom: 1rem;
      }

      .headline-lv3 {
        font-size: $font-size-22;
        font-weight: $font-weight-light;
        color: $color-heading-lv3;
        margin-top: 2rem;
        margin-bottom: 1rem;
      }

      p {
        margin-bottom: 1.5rem;
        overflow-x: auto;
      }

      blockquote {
        font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
        font-size: 1em;
        margin: 2em;
        background: #f9f9f9;
        border-left: 10px solid #abdcec;
        padding: 2em;
      }

      ul {
        padding: 0;

        li {
          position: relative;
          list-style: none;
          padding-left: 1.75rem;
          margin-bottom: 0.75rem;

          &:before {
            position: absolute;
            content: '';
            top: 0.2rem;
            left: 0;
            width: 1rem;
            height: 1rem;
            background: url('../../img/bullet-icon.png');
            background-size: 1rem;
          }
        }
      }

      ol {
        li {
          margin-bottom: 0.75rem;
        }
      }

      .collapseLink {
        position: relative;
        padding-right: 1.25rem;

        &:after {
          position: absolute;
          content: '';
          top: 0;
          right: 0;
          width: 1rem;
          height: 1rem;
          background: url('../../img/arrow-icon.png');
          background-size: 1rem;
          transform: rotate(90deg);
        }

        &.collapsed {
          &:after {
            transform: rotate(0deg);
          }
        }
      }

      .spacer {
        height: 3rem;
      }

      .dataTables_wrapper {
        margin: 1.5rem 0 3rem 0;

        label {
          margin: 0.5rem;
        }

        .dataTables_filter input {
          border-radius: .5rem;
          padding-right: 3rem;
          background-image: url(../../img/search.svg);
          background-position: 95% center;
          background-repeat: no-repeat;
          background-size: 24px;
        }

        .dataTables_paginate .paginate_button {
          padding: .15rem .5rem!important;
          min-width: unset!important;
        }
      }
    }

    .content a[href^="http"]::after
    {
      content: "";
      width: 13px;
      height: 13px;
      margin-left: 4px;
      margin-right: 2px;
      background-image: url('../../img/ext_link.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
    }

    .bottomNavigation {
      position: relative;
      margin: 4rem 0;

      .line {
        border-bottom: 2px solid $color-dark-blue;
      }

      .navigationWrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 6rem;
        height: 1.5rem;
        transform: translate(-50%, -50%);
        background: $color-white;
        text-align: center;

        .navArrow {
          display: inline-block;
          width: 1rem;
          height: 1rem;
          background: url('../../img/arrow-icon.png');
          margin: 0.25rem;

          &.top {
            transform: rotate(-90deg);
          }

          &.left {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
