/*-------------------------------------------------------------------------*/
/*	Global */
/*-------------------------------------------------------------------------*/

html {
    font-size: 100%;
}


body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    @extend .FFClanWebProBook;

    b, strong {
        @extend .FFClanWebProMedium;
    }
}

a {
    color: $color-primary;
    &:hover {
        color: $color-primary;
    }
}

.hidden {
    display: none !important;
}

.no-padding {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

input,
input:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  box-shadow: none;
  outline: 0 none;
}


#formular  input {
    position: relative;
    background: none;
    box-shadow: none;
    border-radius: 0.5rem;
    border: solid 0.063rem $color-darkgrey;
    background-color: $color-white;
    line-height: 2rem;

    @include media-breakpoint-up(xl) {
    line-height: 2.5rem;    
    font-size: $font-size-18;
    background-size: 28px;
    }
    &::placeholder {
      color: $color-black;
    }
}

#formular input:checked{
    border: solid 0.063rem $color-darkgrey;
    background-color: $color-light-green;
}

#formular button {
    display: block;
    font-size: $font-size-16;
    background-color: $color-green;
    color: $color-white;
    text-decoration: none;
    line-height: 2.2rem;
    height: 2rem;
    padding: 0rem 0.625rem;
    border: none;
    border-radius: 0.5rem;  
    -moz-osx-font-smoothing: auto!important;  
    @include media-breakpoint-up(xl){
    font-size: $font-size-22;
    padding: 0rem 1rem;
    line-height: 2.7rem;
    height: 2.5rem;
    }
}

.login.spaces div {
    margin-bottom: 1rem;
}
